import React, { useState, useEffect } from 'react';
import { Settings, User, Bell, AlertCircle, LogIn, X } from 'lucide-react';

const Dashboard = () => {
  const [cars, setCars] = useState([
    {carID: "001", isPlus: 0},
    {carID: "002", isPlus: 0},
    {carID: "大大怪", isPlus: 1},
    {carID: "皮皮虾", isPlus: 1},
  ]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [notice, setNotice] = useState('暂无公告');
  const [token, setToken] = useState('');
  const [userInfo, setUserInfo] = useState(null);
  const [carStatuses, setCarStatuses] = useState({});
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [loginStatus, setLoginStatus] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showTokenModal, setShowTokenModal] = useState(false);
  const [selectedCarId, setSelectedCarId] = useState(null);

  useEffect(() => {
    // Check screen size
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    
    // Check for existing token in cookies
    const userToken = getCookie('usertoken');
    if (userToken) {
      setToken(userToken);
    }

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    fetchCars();
    const carsInterval = setInterval(fetchCars, 30000);

    // Status checking interval
    const statusInterval = setInterval(checkLoginStatus, 1000);

    return () => {
      clearInterval(carsInterval);
      clearInterval(statusInterval);
    };
  }, []);

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };

  const setCookie = (name, value, days = 7) => {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value};${expires};path=/`;
  };

  const handleTokenSubmit = async () => {
    if (!token) return;

    try {
      const response = await fetch('/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `usertoken=${encodeURIComponent(token)}&action=default`
      });

      if (response.ok) {
        setCookie('usertoken', token);
        setShowTokenModal(false);
        
        if (selectedCarId) {
          window.location.href = `/auth/login?carid=${encodeURIComponent(selectedCarId)}`;
        }
      } else {
        setError('Token验证失败，请重试');
      }
    } catch (err) {
      console.error('Error submitting token:', err);
      setError('提交token时发生错误');
    }
  };

  const checkLoginStatus = async () => {
    const userToken = getCookie('usertoken');
    if (!userToken) return;

    try {
      const response = await fetch('/chaxun', {
        method: 'GET',
        headers: {
          'Cookie': `usertoken=${userToken}`,
          'Authorization': `Bearer ${userToken}`
        },
        credentials: 'include'
      });
      const data = await response.json();
      setLoginStatus(data);
    } catch (err) {
      console.error('Error checking login status:', err);
      setLoginStatus(null);
    }
  };

  const handleCarClick = (carId) => {
    const userToken = getCookie('usertoken');
    if (!userToken) {
      setSelectedCarId(carId);
      setShowTokenModal(true);
    } else {
      window.location.href = `/auth/login?carid=${encodeURIComponent(carId)}`;
    }
  };

  const fetchCarStatus = async (carID) => {
    try {
      const response = await fetch(`/status?carid=${encodeURIComponent(carID)}`);
      const data = await response.json();
      setCarStatuses(prev => ({
        ...prev,
        [carID]: data
      }));
    } catch (err) {
      console.error('Error fetching car status:', err);
      setCarStatuses(prev => ({
        ...prev,
        [carID]: { count: 0, clears_in: 0 }
      }));
    }
  };

  const fetchCars = async () => {
    try {
      setLoading(true);
      const response = await fetch('/carpage', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ page: 1, size: 80 })
      });

      const data = await response.json();
      
      if (data.code === 1000) {
        const sortedCars = [...data.data.list].sort((a, b) => {
          if (a.isPlus !== b.isPlus) return b.isPlus - a.isPlus;
          return a.carID.localeCompare(b.carID);
        });
        
        setCars(sortedCars);
        setNotice(data.notice || '暂无公告');
        
        sortedCars.forEach(car => fetchCarStatus(car.carID));
      } else {
        setError(`Failed to load car data: ${data.messages || 'Unknown error'}`);
      }
    } catch (err) {
      console.error('Failed to fetch car data:', err);
      setError('Failed to fetch car data');
    } finally {
      setLoading(false);
    }
  };

  const getStatusDisplay = (status) => {
    if (!status) return { text: '检查中...', color: 'text-gray-500' };
    
    if (status.clears_in > 0) {
      return { 
        text: `不可用|冷却时间${status.clears_in}s`, 
        color: 'text-red-500'
      };
    }
    
    if (status.count < 40) {
      return { 
        text: '空闲|可用', 
        color: 'text-green-500'
      };
    }
    
    return { 
      text: '繁忙|可用', 
      color: 'text-yellow-500'
    };
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header remains the same */}
      <header className="bg-white p-4 flex justify-between items-center border-b">
                <div className="flex items-center gap-2">
          <button 
            onClick={() => setSidebarOpen(!isSidebarOpen)}
            className="lg:hidden p-2 hover:bg-gray-100 rounded"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
          <h1 className="text-xl">Chatgpt</h1>
        </div>
        <div className="flex items-center gap-2">
          <button 
            onClick={fetchCars}
            className="px-3 py-1.5 text-sm bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
          >
            刷新
          </button>
          <button className="p-2 hover:bg-gray-100 rounded"><Bell size={20} /></button>
          <button className="p-2 hover:bg-gray-100 rounded"><Settings size={20} /></button>
        </div>
      </header>

      {/* Token Input Modal */}
      {showTokenModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full mx-4">
            <h2 className="text-xl mb-4">请输入Token</h2>
            {error && (
              <div className="flex items-start gap-2 p-3 mb-4 bg-red-50 border border-red-200 text-red-800 rounded-md">
                <AlertCircle className="h-5 w-5 text-red-600 mt-0.5" />
                <div className="flex-1">
                  <div className="font-medium">错误</div>
                  <div className="text-sm">{error}</div>
                </div>
                <button 
                  onClick={() => setError(null)}
                  className="text-red-600 hover:text-red-800"
                >
                  <X size={16} />
                </button>
              </div>
            )}
            <input
              type="text"
              value={token}
              onChange={(e) => setToken(e.target.value)}
              placeholder="输入你的token"
              className="w-full px-3 py-2 border rounded mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <div className="flex justify-end gap-2">
              <button
                onClick={() => setShowTokenModal(false)}
                className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded"
              >
                取消
              </button>
              <button
                onClick={handleTokenSubmit}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                确认
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="flex">
        {/* Sidebar and main content */}
        <aside className={`fixed lg:static inset-y-0 left-0 w-64 bg-white border-r transform transition-transform duration-200 ease-in-out z-50 ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'
        }`}>
          <div className="p-6">
            {/* Logo and Title */}
            <div className="mb-8">
              <h2 className="text-xl font-semibold mb-2">皮皮虾-GPT</h2>
              <p className="text-sm text-gray-500">啤酒小龙虾</p>
            </div>
            
            {/* User Profile Section */}
            <div className="mb-8">
              <div className="flex items-start gap-3">
                <User size={40} className="text-gray-700 mt-1" />
                <div className="flex-1">
                  {!getCookie('usertoken') ? (
                    <div className="flex flex-col gap-2">
                      <div className="font-medium">访客用户</div>
                      <div className="text-sm text-gray-500">请登录以使用服务</div>
                      <input
                        type="text"
                        value={token}
                        onChange={(e) => setToken(e.target.value)}
                        placeholder="输入token"
                        className="w-full px-3 py-1.5 border rounded text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                      <button
                        onClick={handleTokenSubmit}
                        className="w-full px-3 py-1.5 bg-blue-500 text-white rounded text-sm flex items-center justify-center gap-1 hover:bg-blue-600 transition-colors"
                      >
                        <LogIn size={14} />
                        <span>登录</span>
                      </button>
                    </div>
                  ) : loginStatus ? (
                    <div>
                      <div className="font-medium">当前用户</div>
                      <div className="text-sm text-gray-600 mt-2">
                        用户信息：
                      </div>
                      <div className="text-xs text-gray-500 mt-1 space-y-1">
                        <div>剩余次数: {loginStatus.usage_count || 0}</div>
                        <div>重置时间: {loginStatus.retry_after || '未知'}</div>
                        <div className="break-all">
                          Token: {loginStatus.token || getCookie('usertoken')}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="font-medium">正在加载用户信息...</div>
                      <div className="text-sm text-gray-500 mt-1">请稍候</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Navigation Menu */}
            <nav className="mb-8">
              <div className="text-sm font-medium text-gray-600 mb-2">导航菜单</div>
              <div className="space-y-1">
                <button className="w-full flex items-center gap-2 px-3 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-md transition-colors">
                  <Bell size={16} />
                  <span>通知消息</span>
                </button>
                <button className="w-full flex items-center gap-2 px-3 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-md transition-colors">
                  <Settings size={16} />
                  <span>系统设置</span>
                </button>
              </div>
            </nav>

            {/* Notice Section */}
            <div className="mb-8">
              <div className="text-sm font-medium text-gray-600 mb-2">系统公告</div>
              <div className="p-3 bg-blue-50 rounded-md">
                <div 
                  className="text-sm text-gray-600 break-words"
                  dangerouslySetInnerHTML={{ __html: notice }}
                />
              </div>
            </div>

            {/* System Status */}
            <div className="mb-8">
              <div className="text-sm font-medium text-gray-600 mb-2">系统状态</div>
              <div className="space-y-2">
                <div className="flex items-center justify-between text-sm">
                  <span className="text-gray-600">在线车辆</span>
                  <span className="text-gray-900">{cars.length}</span>
                </div>
                <div className="flex items-center justify-between text-sm">
                  <span className="text-gray-600">Plus车辆</span>
                  <span className="text-gray-900">
                    {cars.filter(car => car.isPlus === 1).length}
                  </span>
                </div>
                <div className="flex items-center justify-between text-sm">
                  <span className="text-gray-600">免费车辆</span>
                  <span className="text-gray-900">
                    {cars.filter(car => car.isPlus === 0).length}
                  </span>
                </div>
              </div>
            </div>

            {/* Version Info */}
            <div className="text-xs text-gray-500">
              <div>Version: 1.0.0</div>
              <div>Last Updated: 2024-02-01</div>
            </div>
          </div>
        </aside>

        <main className="flex-1 p-4 lg:p-6 overflow-x-hidden">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {cars.map((car, index) => (
              <div 
                key={`${car.carID}-${index}`}
                onClick={() => handleCarClick(car.carID)}
              >
                <div className="p-4 bg-white rounded-lg shadow-sm cursor-pointer hover:shadow-md transition-all hover:-translate-y-1">
                                    <div className="flex items-center gap-2 mb-3">
                    <span className={`px-2 py-1 text-sm rounded-md ${
                      car.isPlus === 1 ? 'bg-purple-100 text-purple-600' : 'bg-blue-100 text-blue-600'
                    }`}>
                      {car.isPlus === 1 ? 'Plus' : 'Free'}
                    </span>
                    <span className="text-sm">{car.carID}</span>
                  </div>
                  <div className="flex items-center gap-2 text-sm">
                    <span>状态:</span>
                    <span className={getStatusDisplay(carStatuses[car.carID])?.color}>
                      {getStatusDisplay(carStatuses[car.carID])?.text}
                    </span>
                  </div>
                  <div className="mt-3 flex gap-2">
                    <div className="h-2 flex-1 bg-orange-200 rounded"></div>
                    <div className="h-2 flex-1 bg-emerald-200 rounded"></div>
                    <div className="h-2 flex-1 bg-blue-200 rounded"></div>
                    <div className="h-2 flex-1 bg-purple-200 rounded"></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;